<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink" style="opacity: 0">
                        <DxButton
                            type="default"
                            icon="mdi mdi-home"
                            class="ml-1"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">In Vé Lái Xe Bán</div>
                </slot>
                <slot name="after">
                    <div class="shrink" style="opacity: 0">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll-events="false">
            <div class="py-3 px-2">
                <div
                    class="row align-center justify-center"
                    style="height: calc(100vh - 164px)"
                >
                    <div class="xs12 text-xs-center">
                        <img
                            src="../../assets/images/print.gif"
                            style="width: 214px; height: 244px"
                        />
                        <img
                            src="../../assets/images/292.gif"
                            style="width: 214px; height: 30px"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="xs12">
                        <DxButton
                            :text="`In lại vé ${
                                timeOut > 0 ? `(${timeOut})` : ''
                            }`"
                            type="default"
                            width="100%"
                            styling-mode="contained"
                            :disabled="timeOut > 0"
                            @click="InLaiVe()"
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="xs12">
                        <DxButton
                            text="Tiếp tục bán vé"
                            styling-mode="outlined"
                            type="default"
                            width="100%"
                            @click="$router.push('/tabs/tab3')"
                        />
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import {
    IonContent,
    IonPage,
    IonToolbar,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
export default {
    components: {
        DxButton,
        IonContent,
        IonPage,
        IonToolbar,
    },
    data() {
        return {
            queryHeader: {},
            timeOut: 0,
            funcTimeOut: null,
        };
    },
    methods: {
        ToastThongBao(type, msg) {
            let toast = {
                type: type,
                message: msg,
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        async layThongTinVe(maGiaoDich) {
            try {
                this.$startLoading;
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVe"),
                    "maGiaoDich",
                    maGiaoDich
                );
                if (rs == "error") {
                    return this.$emit("thongBaoLoi", "error", "In thất bại! ");
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },
        InLaiVe() {
            this.startTimeOut();
            if (this.$route.query.maGiaoDich) {
                this.layThongTinVe(this.$route.query.maGiaoDich);
            }
        },
        startTimeOut() {
            this.funcTimeOut = null;
            this.timeOut = 5;
            this.funcTimeOut = setInterval(() => {
                this.timeOut--;
                if (this.timeOut == 0) {
                    clearInterval(this.funcTimeOut);
                }
            }, 1000);
        },
    },
    created() {
        onIonViewWillEnter(() => {
            if (this.$route.query.maGiaoDich) {
                this.layThongTinVe(this.$route.query.maGiaoDich);
            }
        });

        onIonViewWillLeave(() => {
            localStorage.removeItem("TenCho");
        });
    },
};
</script>

<style scoped></style>
